import React from "react";
import logo from "../logo.svg";
// import "tnv_logo.png";

const About = () => {
  return (
    <header className="App-header">
      <h1>About The Next VIP</h1>
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      <p className="center-code">
        <code>
          Vin Sharma is the founder of The Next VIP and is Head of Talent
          Discovery and New Artist Development. He studied economics,
          management, and computer science at Boston University. He began his
          career in artist management working with national recording artists
          signed to Universal/Republic Records, Interscope Geffen A&M, WME,
          Sony/Epic, and others. Vin worked on several engineering and product
          teams from the founder-based stage through growth stage acquisition.
          He is a professionally trained singer, songwriter, recording artist,
          and guitarist. His song “Machine” has received mainstream radio
          airplay, and has been downloaded and streamed nationally across 47
          states and globally across 68 countries throughout South America,
          Europe, Africa, Asia, and Australia.
        </code>
      </p>

      <p className="center-code">
        <code>
          The Next VIP is a brand new way for musicians to connect with fans and
          the music industry online and digitally. Members will be eligible to
          enter contests and win prizes. Call us at the number below for more
          information on how to become a member and membership rates.
        </code>
      </p>
      <h5>To enroll, call our direct line at: 508-241-8964</h5>
    </header>
  );
};

export default About;
