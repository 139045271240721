import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    // Joi validation
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;

    /// map the array into an object
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  validateProperty = ({ name, value }) => {
    // use computer properties from ES6 to pass name, whose value will be username or password
    console.log("schema", this.schema);

    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    //we do not want a full page reload
    e.preventDefault();

    // Error handling:
    // call this.validate(), which returns errors{} object.
    // set state with result.
    const errors = this.validate();
    // if errors is truthy, assign the value of errors, else assign an empty object
    this.setState({ errors: errors || {} });
    if (errors) return; // do not call the server if there are errors.

    this.doSubmit();
  };

  // when the text input is changed, the state is updated, and form re-renders, and shows the
  // updated form input value
  // We had this: handleChange = e => {. We destructured e to get currentTarget and rename to input
  handleChange = ({ currentTarget: input }) => {
    // validate the input that is changing
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    //create new data and clone the state into it.
    const data = { ...this.state.data };
    // get value from the input field
    // working with properties of an object dynamically, use bracket notation
    // set name attribute in the input field
    data[input.name] = input.value;
    // set the new data object value
    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={true}
      />
    );
  }
}

export default Form;
