import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import SplashPage from "./components/splashPage";
import NavBar from "./components/navbar";
import About from "./components/about";
import LoginForm from "./components/loginForm";

function App() {
  return (
    <div>
      <NavBar />
      <Switch>
        <Route
          className="nav-item nav-link active"
          path="/login"
          component={LoginForm}
        />
        <Route
          className="nav-item nav-link active"
          path="/about"
          component={About}
        />
        <Route
          className="nav-item nav-link active"
          path="/splash"
          component={SplashPage}
        />
        {/* <Route
          className="nav-item nav-link active"
          path="/not-found"
          component={NotFound}
        /> */}
        <Redirect from="/" exact to="/splash" />
        {/* <Redirect to="/not-found" /> */}
      </Switch>
      <div className="App">
        <footer className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 txt-right-sm">
              <p>
                &#169;2019&nbsp;The Next VIP.&nbsp;All&nbsp;Rights&nbsp;Reserved
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
