import React from "react";
import logo from "../logo.svg";

const SplashPage = () => {
  return (
    <header className="App-header">
      <h1>The Next VIP</h1>
      <img src={logo} className="App-logo" alt="logo" />
      <h4>Membership plans begin at $35 per month</h4>
      <h4>To enroll, call our direct line at: 508-241-8964</h4>
      <h5>The Next VIP is a way for musicians to connect digitally.</h5>
      <h5>
        Members of The Next VIP can enter contests, win prizes, and engage with
        fans online.
      </h5>
    </header>
  );
};

export default SplashPage;
