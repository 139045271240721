import React, { Component } from "react";
// import { Joi } from "joi-browser";
import Form from "./common/form";

const Joi = require("joi-browser");

class LoginForm extends Form {
  state = {
    data: {
      username: "",
      password: ""
    },
    errors: {}
  };

  // scheme for JOI validation

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  doSubmit = () => {
    // call the server
  };

  render() {
    return (
      <div className="container">
        <h1>Login</h1>
        <h2>To set up your account, call our direct line at: 508-241-8964</h2>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Login")}
        </form>
      </div>
    );
  }
}

export default LoginForm;
